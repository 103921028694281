import { default as articleFxocv4itaaMeta } from "/opt/buildhome/repo/pages/article.vue?macro=true";
import { default as termsjw1825O2ZdMeta } from "/opt/buildhome/repo/pages/terms.vue?macro=true";
import { default as termBISVRXU5NKMeta } from "/opt/buildhome/repo/pages/term.vue?macro=true";
import { default as clienttestzDPik2cn8JMeta } from "/opt/buildhome/repo/pages/clienttest.vue?macro=true";
import { default as pricesZgUgUL3BAzMeta } from "/opt/buildhome/repo/pages/prices.vue?macro=true";
import { default as wayfrVQRdCshUUMeta } from "/opt/buildhome/repo/pages/wayf.vue?macro=true";
import { default as cookieinfo4GvOZOzBw9Meta } from "/opt/buildhome/repo/pages/cookieinfo.vue?macro=true";
import { default as login4dfr6kRqBoMeta } from "/opt/buildhome/repo/pages/login.vue?macro=true";
import { default as ssoLogin7BvNhLICwSMeta } from "/opt/buildhome/repo/pages/ssoLogin.vue?macro=true";
import { default as legacyMmDEtBc1xSMeta } from "/opt/buildhome/repo/pages/legacy.vue?macro=true";
import { default as indexy4DdxYIhQyMeta } from "/opt/buildhome/repo/pages/account/index.vue?macro=true";
import { default as profilerpPZ78QyoXMeta } from "/opt/buildhome/repo/pages/account/profile.vue?macro=true";
import { default as loginsecurityEakWPXnG3QMeta } from "/opt/buildhome/repo/pages/account/loginsecurity.vue?macro=true";
import { default as savedArticles5hTOP89LjbMeta } from "/opt/buildhome/repo/pages/account/savedArticles.vue?macro=true";
import { default as decisionchainsiLWeDJNn2PMeta } from "/opt/buildhome/repo/pages/account/decisionchains.vue?macro=true";
import { default as newsletterrIWb0i7VynMeta } from "/opt/buildhome/repo/pages/account/newsletter.vue?macro=true";
import { default as userCreateOwFxp3dE9mMeta } from "/opt/buildhome/repo/pages/userCreate.vue?macro=true";
import { default as userForgotPasswordhobFhtIjWLMeta } from "/opt/buildhome/repo/pages/userForgotPassword.vue?macro=true";
import { default as typographyJkaCdJAZzBMeta } from "/opt/buildhome/repo/pages/typography.vue?macro=true";
import { default as searchW2JMgpGXtYMeta } from "/opt/buildhome/repo/pages/search.vue?macro=true";
import { default as seriesxIUqk3TGcVMeta } from "/opt/buildhome/repo/pages/mm/series.vue?macro=true";
import { default as newsletterdypMw9Ct6LMeta } from "/opt/buildhome/repo/pages/newsletter.vue?macro=true";
import { default as subscription6xxDUrnoUmMeta } from "/opt/buildhome/repo/pages/mm/subscription.vue?macro=true";
import { default as calendarlist0Ry8YTOD2CMeta } from "/opt/buildhome/repo/pages/calendarlist.vue?macro=true";
import { default as calendaryPechgWogzMeta } from "/opt/buildhome/repo/pages/calendar.vue?macro=true";
import { default as magazineARYqoZf72sMeta } from "/opt/buildhome/repo/pages/magazine.vue?macro=true";
import { default as themeweek3L95CfaiEFMeta } from "/opt/buildhome/repo/pages/themeweek.vue?macro=true";
import { default as subjectE34YW7u68vMeta } from "/opt/buildhome/repo/pages/mm/subject.vue?macro=true";
import { default as themeV1NFhoMpcdMeta } from "/opt/buildhome/repo/pages/mm/theme.vue?macro=true";
import { default as personuCNmZMkXgRMeta } from "/opt/buildhome/repo/pages/person.vue?macro=true";
import { default as writerUu6osQqVPuMeta } from "/opt/buildhome/repo/pages/writer.vue?macro=true";
import { default as paperrxl1546S73Meta } from "/opt/buildhome/repo/pages/mm/paper.vue?macro=true";
import { default as jobs1jPXQSk35lMeta } from "/opt/buildhome/repo/pages/jobs.vue?macro=true";
import { default as jobAddtiUyNAGqlMeta } from "/opt/buildhome/repo/pages/jobAd.vue?macro=true";
import { default as rssHyND0BWY79Meta } from "/opt/buildhome/repo/pages/rss.vue?macro=true";
import { default as frontpageMA1qutW7keMeta } from "/opt/buildhome/repo/pages/mm/frontpage.vue?macro=true";
import { default as newJobsaHGJtPfiY2Meta } from "/opt/buildhome/repo/pages/mm/newJobs.vue?macro=true";
import { default as storyInNumbersGzMJV6UgDkMeta } from "/opt/buildhome/repo/pages/storyInNumbers.vue?macro=true";
import { default as comments8x554P9RFwMeta } from "/opt/buildhome/repo/pages/mm/comments.vue?macro=true";
import { default as newLeadershipV8MaN0PF84Meta } from "/opt/buildhome/repo/pages/mm/newLeadership.vue?macro=true";
import { default as chiefEditorsBlogusZ2XMudDxMeta } from "/opt/buildhome/repo/pages/mm/chiefEditorsBlog.vue?macro=true";
import { default as techTrendsSZJ915vwISMeta } from "/opt/buildhome/repo/pages/mm/techTrends.vue?macro=true";
import { default as cultureRecommendation2iJceLUg5qMeta } from "/opt/buildhome/repo/pages/mm/cultureRecommendation.vue?macro=true";
import { default as audioArticlesRhMu0K10qjMeta } from "/opt/buildhome/repo/pages/mm/audioArticles.vue?macro=true";
import { default as latestArticlesrxD2XcqTv8Meta } from "/opt/buildhome/repo/pages/mm/latestArticles.vue?macro=true";
import { default as yearbook0DFMuQCEESMeta } from "/opt/buildhome/repo/pages/yearbook.vue?macro=true";
import { default as component_45stubGZr1UkTkg3Meta } from "/opt/buildhome/repo/node_modules/nuxt/dist/pages/runtime/component-stub.js?macro=true";
import { default as component_45stubGZr1UkTkg3 } from "/opt/buildhome/repo/node_modules/nuxt/dist/pages/runtime/component-stub.js";
export default [
  {
    name: "article",
    path: "/artikel/:id",
    component: () => import("/opt/buildhome/repo/pages/article.vue").then(m => m.default || m)
  },
  {
    name: "terms",
    path: "/handelsbetingelser",
    component: () => import("/opt/buildhome/repo/pages/terms.vue").then(m => m.default || m)
  },
  {
    name: "term",
    path: "/handelsbetingelser/:termId",
    component: () => import("/opt/buildhome/repo/pages/term.vue").then(m => m.default || m)
  },
  {
    name: "clienttest",
    path: "/ct",
    component: () => import("/opt/buildhome/repo/pages/clienttest.vue").then(m => m.default || m)
  },
  {
    name: "prices",
    path: "/Priser",
    component: () => import("/opt/buildhome/repo/pages/prices.vue").then(m => m.default || m)
  },
  {
    name: "wayflogin",
    path: "/wayflogin",
    component: () => import("/opt/buildhome/repo/pages/wayf.vue").then(m => m.default || m)
  },
  {
    name: "cookieinfo",
    path: "/cookieinfo",
    component: () => import("/opt/buildhome/repo/pages/cookieinfo.vue").then(m => m.default || m)
  },
  {
    name: "login",
    path: "/login",
    component: () => import("/opt/buildhome/repo/pages/login.vue").then(m => m.default || m)
  },
  {
    name: "ssoLogin",
    path: "/sso-login",
    component: () => import("/opt/buildhome/repo/pages/ssoLogin.vue").then(m => m.default || m)
  },
  {
    name: "payment",
    path: "/betal",
    meta: {"url":"/betal.aspx"},
    component: () => import("/opt/buildhome/repo/pages/legacy.vue").then(m => m.default || m)
  },
  {
    name: "afmeld",
    path: "/afmeld",
    meta: {"url":"/afmeld.aspx"},
    component: () => import("/opt/buildhome/repo/pages/legacy.vue").then(m => m.default || m)
  },
  {
    name: "account",
    path: "/konto",
    component: () => import("/opt/buildhome/repo/pages/account/index.vue").then(m => m.default || m)
  },
  {
    name: "account_profile",
    path: "/konto/profil",
    component: () => import("/opt/buildhome/repo/pages/account/profile.vue").then(m => m.default || m)
  },
  {
    name: "account_loginsecurity",
    path: "/konto/login-sikkerhed",
    component: () => import("/opt/buildhome/repo/pages/account/loginsecurity.vue").then(m => m.default || m)
  },
  {
    name: "saved_articles",
    path: "/konto/gemte-artikler",
    component: () => import("/opt/buildhome/repo/pages/account/savedArticles.vue").then(m => m.default || m)
  },
  {
    name: "account_decisionchains",
    path: "/konto/beslutningskaeder",
    component: () => import("/opt/buildhome/repo/pages/account/decisionchains.vue").then(m => m.default || m)
  },
  {
    name: "account_newsletter",
    path: "/konto/nyhedsbrev",
    component: () => import("/opt/buildhome/repo/pages/account/newsletter.vue").then(m => m.default || m)
  },
  {
    name: "admin",
    path: "/admin",
    meta: {"url":"/admin"},
    component: () => import("/opt/buildhome/repo/pages/legacy.vue").then(m => m.default || m)
  },
  {
    name: "nejtak",
    path: "/nejtak",
    meta: {"url":"/nejtak.aspx"},
    component: () => import("/opt/buildhome/repo/pages/legacy.vue").then(m => m.default || m)
  },
  {
    name: "usercreatemm",
    path: "/usercreatemm",
    meta: {"url":"/usercreatemm"},
    component: () => import("/opt/buildhome/repo/pages/userCreate.vue").then(m => m.default || m)
  },
  {
    name: "userconfirmation",
    path: "/userconfirmation",
    meta: {"url":"/userconfirmation"},
    component: () => import("/opt/buildhome/repo/pages/legacy.vue").then(m => m.default || m)
  },
  {
    name: "useravatar",
    path: "/useravatar",
    meta: {"url":"/useravatar"},
    component: () => import("/opt/buildhome/repo/pages/legacy.vue").then(m => m.default || m)
  },
  {
    name: "userforgotpassword",
    path: "/userforgotpassword",
    meta: {"url":"/userforgotpassword"},
    component: () => import("/opt/buildhome/repo/pages/userForgotPassword.vue").then(m => m.default || m)
  },
  {
    name: "copyright",
    path: "/copyright",
    meta: {"url":"/copyright.aspx"},
    component: () => import("/opt/buildhome/repo/pages/legacy.vue").then(m => m.default || m)
  },
  {
    name: "licens",
    path: "/licens",
    meta: {"url":"/licens"},
    component: () => import("/opt/buildhome/repo/pages/legacy.vue").then(m => m.default || m)
  },
  {
    name: "typography",
    path: "/typography",
    component: () => import("/opt/buildhome/repo/pages/typography.vue").then(m => m.default || m)
  },
  {
    name: "search",
    path: "/soeg",
    component: () => import("/opt/buildhome/repo/pages/search.vue").then(m => m.default || m)
  },
  {
    name: "searchwords",
    path: "/soeg/:words",
    component: () => import("/opt/buildhome/repo/pages/search.vue").then(m => m.default || m)
  },
  {
    name: "serie",
    path: "/serie/:subject",
    component: () => import("/opt/buildhome/repo/pages/mm/series.vue").then(m => m.default || m)
  },
  {
    name: "newsletter",
    path: "/nyhedsbrev",
    component: () => import("/opt/buildhome/repo/pages/newsletter.vue").then(m => m.default || m)
  },
  {
    name: "subscription",
    path: "/abonnement",
    component: () => import("/opt/buildhome/repo/pages/mm/subscription.vue").then(m => m.default || m)
  },
  {
    name: "subscription_type",
    path: "/abonnement/:typeId",
    component: () => import("/opt/buildhome/repo/pages/mm/subscription.vue").then(m => m.default || m)
  },
  {
    name: "subscription_type_information",
    path: "/abonnement/:typeId/information",
    component: () => import("/opt/buildhome/repo/pages/mm/subscription.vue").then(m => m.default || m)
  },
  {
    name: "cal",
    path: "/kalender",
    component: () => import("/opt/buildhome/repo/pages/calendarlist.vue").then(m => m.default || m)
  },
  {
    name: "calendar",
    path: "/kalender/:id",
    component: () => import("/opt/buildhome/repo/pages/calendar.vue").then(m => m.default || m)
  },
  {
    name: "paper_cal",
    path: "/:paper/kalender",
    component: () => import("/opt/buildhome/repo/pages/calendarlist.vue").then(m => m.default || m)
  },
  {
    name: "paper_calendar",
    path: "/:paper/kalender/:id",
    component: () => import("/opt/buildhome/repo/pages/calendar.vue").then(m => m.default || m)
  },
  {
    name: "magazine",
    path: "/ugebreve",
    component: () => import("/opt/buildhome/repo/pages/magazine.vue").then(m => m.default || m)
  },
  {
    name: "themeweek2",
    path: "/ugenstemaer",
    component: () => import("/opt/buildhome/repo/pages/themeweek.vue").then(m => m.default || m)
  },
  {
    name: "series",
    path: "/serier",
    component: () => import("/opt/buildhome/repo/pages/themeweek.vue").then(m => m.default || m)
  },
  {
    name: "subject",
    path: "/emne/:subject",
    component: () => import("/opt/buildhome/repo/pages/mm/subject.vue").then(m => m.default || m)
  },
  {
    name: "paper_subject",
    path: "/:paper/emne/:subject",
    component: () => import("/opt/buildhome/repo/pages/mm/subject.vue").then(m => m.default || m)
  },
  {
    name: "theme",
    path: "/tema/:theme",
    component: () => import("/opt/buildhome/repo/pages/mm/theme.vue").then(m => m.default || m)
  },
  {
    name: "person",
    path: "/person/:person",
    component: () => import("/opt/buildhome/repo/pages/person.vue").then(m => m.default || m)
  },
  {
    name: "paper_person",
    path: "/:paper/person/:person",
    component: () => import("/opt/buildhome/repo/pages/person.vue").then(m => m.default || m)
  },
  {
    name: "writer",
    path: "/forfatter/:writer",
    component: () => import("/opt/buildhome/repo/pages/writer.vue").then(m => m.default || m)
  },
  {
    name: "paper_writer",
    path: "/:paper/forfatter/:writer",
    component: () => import("/opt/buildhome/repo/pages/writer.vue").then(m => m.default || m)
  },
  {
    name: "paper",
    path: "/:paper",
    component: () => import("/opt/buildhome/repo/pages/mm/paper.vue").then(m => m.default || m)
  },
  {
    name: "paper_article",
    path: "/:paper/artikel/:id",
    component: () => import("/opt/buildhome/repo/pages/article.vue").then(m => m.default || m)
  },
  {
    name: "paper_names",
    path: "/:paper/navnenyt/:id",
    component: () => import("/opt/buildhome/repo/pages/article.vue").then(m => m.default || m)
  },
  {
    name: "jobs",
    path: "/job",
    component: () => import("/opt/buildhome/repo/pages/jobs.vue").then(m => m.default || m)
  },
  {
    name: "job",
    path: "/job/:id",
    component: () => import("/opt/buildhome/repo/pages/jobAd.vue").then(m => m.default || m)
  },
  {
    name: "names",
    path: "/navnenyt/:id",
    component: () => import("/opt/buildhome/repo/pages/article.vue").then(m => m.default || m)
  },
  {
    name: "rss",
    path: "/rss/:id",
    component: () => import("/opt/buildhome/repo/pages/rss.vue").then(m => m.default || m)
  },
  {
    name: "frontpage",
    path: "/",
    component: () => import("/opt/buildhome/repo/pages/mm/frontpage.vue").then(m => m.default || m)
  },
  {
    name: "new-jobs",
    path: "/nyt-job",
    component: () => import("/opt/buildhome/repo/pages/mm/newJobs.vue").then(m => m.default || m)
  },
  {
    name: "story-in-numbers",
    path: "/historien-i-tal",
    component: () => import("/opt/buildhome/repo/pages/storyInNumbers.vue").then(m => m.default || m)
  },
  {
    name: "comments",
    path: "/kommentarer",
    component: () => import("/opt/buildhome/repo/pages/mm/comments.vue").then(m => m.default || m)
  },
  {
    name: "new-leadership",
    path: "/nyt-lederskab",
    component: () => import("/opt/buildhome/repo/pages/mm/newLeadership.vue").then(m => m.default || m)
  },
  {
    name: "chief-editors-blog",
    path: "/chefredaktorens-blog",
    component: () => import("/opt/buildhome/repo/pages/mm/chiefEditorsBlog.vue").then(m => m.default || m)
  },
  {
    name: "tech-trends",
    path: "/techtendenser",
    component: () => import("/opt/buildhome/repo/pages/mm/techTrends.vue").then(m => m.default || m)
  },
  {
    name: "culture-recommendation",
    path: "/kulturanbefalingen",
    component: () => import("/opt/buildhome/repo/pages/mm/cultureRecommendation.vue").then(m => m.default || m)
  },
  {
    name: "audio-articles",
    path: "/oplaeste-artikler",
    component: () => import("/opt/buildhome/repo/pages/mm/audioArticles.vue").then(m => m.default || m)
  },
  {
    name: "latest-articles",
    path: "/seneste-artikler",
    component: () => import("/opt/buildhome/repo/pages/mm/latestArticles.vue").then(m => m.default || m)
  },
  {
    name: "yearbook",
    path: "/yearbook",
    component: () => import("/opt/buildhome/repo/pages/yearbook.vue").then(m => m.default || m)
  },
  {
    name: component_45stubGZr1UkTkg3Meta?.name,
    path: "/misc/:pathMatch(.*)",
    component: component_45stubGZr1UkTkg3
  },
  {
    name: component_45stubGZr1UkTkg3Meta?.name,
    path: "/images/:pathMatch(.*)",
    component: component_45stubGZr1UkTkg3
  },
  {
    name: component_45stubGZr1UkTkg3Meta?.name,
    path: "/Scripts/:pathMatch(.*)",
    component: component_45stubGZr1UkTkg3
  },
  {
    name: component_45stubGZr1UkTkg3Meta?.name,
    path: "/scripts/:pathMatch(.*)",
    component: component_45stubGZr1UkTkg3
  },
  {
    name: component_45stubGZr1UkTkg3Meta?.name,
    path: "/css/:pathMatch(.*)",
    component: component_45stubGZr1UkTkg3
  },
  {
    name: component_45stubGZr1UkTkg3Meta?.name,
    path: "/pdffiles/:pathMatch(.*)",
    component: component_45stubGZr1UkTkg3
  },
  {
    name: component_45stubGZr1UkTkg3Meta?.name,
    path: "/arena",
    component: component_45stubGZr1UkTkg3
  },
  {
    name: component_45stubGZr1UkTkg3Meta?.name,
    path: "/praktik",
    component: component_45stubGZr1UkTkg3
  },
  {
    name: component_45stubGZr1UkTkg3Meta?.name,
    path: "/praktik/",
    component: component_45stubGZr1UkTkg3
  },
  {
    name: component_45stubGZr1UkTkg3Meta?.name,
    path: "/hotshot",
    component: component_45stubGZr1UkTkg3
  },
  {
    name: component_45stubGZr1UkTkg3Meta?.name,
    path: "/hotshot/:pathMatch(.*)",
    component: component_45stubGZr1UkTkg3
  },
  {
    name: component_45stubGZr1UkTkg3Meta?.name,
    path: "/proeve.aspx",
    component: component_45stubGZr1UkTkg3
  },
  {
    name: component_45stubGZr1UkTkg3Meta?.name,
    path: "/:paper/proeve.aspx",
    component: component_45stubGZr1UkTkg3
  },
  {
    name: component_45stubGZr1UkTkg3Meta?.name,
    path: "/navnenyt.aspx",
    component: component_45stubGZr1UkTkg3
  },
  {
    name: component_45stubGZr1UkTkg3Meta?.name,
    path: "/soeg.aspx",
    component: component_45stubGZr1UkTkg3
  },
  {
    name: component_45stubGZr1UkTkg3Meta?.name,
    path: "/jobannoncer",
    component: component_45stubGZr1UkTkg3
  },
  {
    name: component_45stubGZr1UkTkg3Meta?.name,
    path: "/karriere",
    component: component_45stubGZr1UkTkg3
  },
  {
    name: component_45stubGZr1UkTkg3Meta?.name,
    path: "/karriere/:pathMatch(.*)",
    component: component_45stubGZr1UkTkg3
  },
  {
    name: component_45stubGZr1UkTkg3Meta?.name,
    path: "/artikel/cookie-og-privatlivspolitik",
    component: component_45stubGZr1UkTkg3
  },
  {
    name: component_45stubGZr1UkTkg3Meta?.name,
    path: "/artikel/handelsbetingelser-for-ugebrevet-mandag-morgen",
    component: component_45stubGZr1UkTkg3
  },
  {
    name: component_45stubGZr1UkTkg3Meta?.name,
    path: "/artikel/priser-og-betingelser",
    component: component_45stubGZr1UkTkg3
  },
  {
    name: component_45stubGZr1UkTkg3Meta?.name,
    path: "/artikel/mandag-morgens-redaktion",
    component: component_45stubGZr1UkTkg3
  },
  {
    name: component_45stubGZr1UkTkg3Meta?.name,
    path: "/artikel/medarbejdere",
    component: component_45stubGZr1UkTkg3
  },
  {
    name: component_45stubGZr1UkTkg3Meta?.name,
    path: "/tjekdet",
    component: component_45stubGZr1UkTkg3
  },
  {
    name: component_45stubGZr1UkTkg3Meta?.name,
    path: "/digital-omstilling",
    component: component_45stubGZr1UkTkg3
  },
  {
    name: component_45stubGZr1UkTkg3Meta?.name,
    path: "/politik",
    component: component_45stubGZr1UkTkg3
  },
  {
    name: component_45stubGZr1UkTkg3Meta?.name,
    path: "/digital-omstilling/artikel",
    component: component_45stubGZr1UkTkg3
  },
  {
    name: component_45stubGZr1UkTkg3Meta?.name,
    path: "/politik/artikel",
    component: component_45stubGZr1UkTkg3
  }
]